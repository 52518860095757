<template>
  <v-card flat class="px-3 mx-sm-5">
    <v-alert
      dismissible
      text
      outlined
      type="error"
      v-if="brandRolesErrorMessage"
      class="mt-4 col-12 text-center"
    >
      {{ brandRolesErrorMessage }}
    </v-alert>
    <v-alert
      dismissible
      text
      outlined
      type="error"
      v-if="inviteBrandUserErrorMessage"
      class="mt-4 col-12 text-center"
    >
      {{ inviteBrandUserErrorMessage }}
    </v-alert>
    <v-alert
      dismissible
      text
      outlined
      type="error"
      v-if="featuresErrorMessage"
      class="mt-4 col-12 text-center"
    >
      {{ featuresErrorMessage }}
    </v-alert>
    <v-card-title>{{ $t('ManageBrandUserSecurityAccess') }}</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="inviteEmailBrandUser"
        append-icon="fa-plus-circle"
        v-if="brand && brand.features.userQuota"
        :disabled="brandRoles.length === brand.features.userQuota"
        label="E-mail"
        type="email"
        ref="inviteEmailBrandUserForm"
        :rules="[
          (value) => !!value.trim() || $t('nameRules'),
          (value) => isEmail(value) || $t('PleaseEnterAValidEmail'),
          (value) =>
            !isBrandUserAlreadyExist(value) || $t('EmailDuplicatedInList'),
        ]"
        @keyup.enter="inviteBrandUser"
        outlined
        @click:append="inviteBrandUser"
      ></v-text-field>
      <v-alert
        outlined
        type="info"
        text
        v-if="brand && brandRoles.length === brand.features.userQuota"
      >
        {{ $t('userQuotaReached') }}
      </v-alert>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchBrandRoles"
        append-icon="fas fa-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headersBrandRoles"
        :items="brandRoles"
        :search="searchBrandRoles"
        :loading="loadingTableRoles"
        :custom-filter="customFilter"
        :footer-props="{
          itemsPerPageOptions: [15, 50, 100, -1],
        }"
      >
        <template v-slot:[`item.user`]="{ item }">
          <router-link
            :to="{ name: 'User', params: { userUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            <span v-if="item.firstname !== ' ' || item.lastname !== ' '">
              {{ item.firstname }} - <strong>{{ item.lastname }}</strong></span>
            <span v-else>
              <v-icon x-small>fas fa-pen</v-icon>
              {{ $t('CompleteTheProfile') }}
            </span>
          </router-link>
          <span> <br>{{ item.email }}</span>
        </template>
        <template v-slot:[`item.admin`]="{ item }">
          <v-switch
            v-model="item.admin"
            color="success"
            inset
            @change="roleChange"
          ></v-switch>
        </template>
        <template v-slot:[`item.access`]="{ item }">
          <v-switch
            v-model="item.access"
            color="success"
            inset
            @change="roleChange"
          ></v-switch>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="error"
        class="text-none mr-2"
        @click="cancelBrandAccessSecurity"
        :disabled="!roleModified"
      >
        <v-icon small class="mr-1">
          fa fa-ban
        </v-icon> {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        color="success"
        class="text-none"
        @click="submitBrandAccessSecurity"
        :disabled="!roleModified"
      >
        <v-icon small class="mr-1">
          fa fa-check
        </v-icon> {{ $t('Submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import isEmail from 'validator/lib/isEmail';
import { ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandUserAndSecurity.i18n.json');

export default {
  name: 'PageBrandUserAndSecurity',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brand: null,
      brandRolesData: null,
      brandRoles: [],
      headersBrandRoles: [],
      searchBrandRoles: '',
      inviteEmailBrandUser: '',
      loadingTableRoles: false,
      roleModified: false,
      isEmail,
      brandRolesErrorMessage: null,
      featuresErrorMessage: null,
      inviteBrandUserErrorMessage: null,
    };
  },
  created() {
    /**
     * GET BRAND ROLES
     */
    this.getBrandRoles();
    /**
     * GET BRAND
     */
    this.getBrand();
  },

  mounted() {
    this.headersBrandRoles = [
      { text: this.$t('user'), value: 'user' },
      { text: this.$t('admin'), value: 'admin' },
      { text: this.$t('access'), value: 'access' },
    ];
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      search = search.toString().toLowerCase();
      if ((item.email + ' ' + item.firstname + ' ' + item.lastname + ' ' + item.firstname).toString().toLowerCase().includes(search)) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    /**
     * GET BRAND ROLES
     */
    getBrandRoles() {
      this.loadingTableRoles = true;
      this.$apiInstance
        .getBrandRoles(this.$route.params.brandUUID)
        .then(
          (brandRolesData) => {
            this.brandRolesData = brandRolesData;
            this.parseBrandRoles(this.brandRolesData);
          },
          (error) => {
            /**
             * ERROR GET BRAND ROLES
             */
            this.brandRolesErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTableRoles = false;
        });
    },
    /**
     * GET BRAND FEATURES
     */
    getBrand() {
      this.$apiInstance.getAdminBrand(this.$route.params.brandUUID).then(
        (brand) => {
          
          if (!brand.features.startup || brand.features.startup === null) {
            brand.features.startup = {
              isManager: false,
              managedBy: '',
            };
          } else if (
            brand.features.startup.isManager ||
            brand.features.startup.managedBy !== ''
          ) {
            this.startupFeatureEnabled = true;
          }
          this.brand = brand;
          this.paymentMethod = brand.features.defaultPaymentMethod.toUpperCase();
        },
        (error) => {
          /**
           * ERROR GET BRAND ADDRESS
           */
          this.featuresErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * PARSING BRAND ROLES
     */
    parseBrandRoles(brandRolesData) {
      const brandRolesDataCopy = JSON.parse(JSON.stringify(brandRolesData));
      const brandRolesArray = [];
      for (const roleUserMapping of brandRolesDataCopy.access) {
        if (!(roleUserMapping._id in brandRolesArray)) {
          brandRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        brandRolesArray[roleUserMapping._id].access = true;
      }
      for (const roleUserMapping of brandRolesDataCopy.admin) {
        if (!(roleUserMapping._id in brandRolesArray)) {
          brandRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        brandRolesArray[roleUserMapping._id].admin = true;
      }
      const brandRoles = [];
      for (const userId in brandRolesArray) {
        if (!brandRolesArray[userId].access) {
          brandRolesArray[userId].access = false;
        }
        if (!brandRolesArray[userId].admin) {
          brandRolesArray[userId].admin = false;
        }
        brandRoles.push(brandRolesArray[userId]);
      }
      this.brandRoles = brandRoles;
    },
    /**
     * CHECK IF USER EXIT IN THE BRAND
     */
    isBrandUserAlreadyExist(email) {
      let found = false;
      this.brandRoles.forEach((user) => {
        if (user.email.toLowerCase() === email.toLowerCase()) {
          found = true;
          return found;
        }
      });
      return found;
    },
    /**
     * INVITE USER TO THE BRAND
     */
    inviteBrandUser() {
      if (this.$refs.inviteEmailBrandUserForm.validate(true)) {
        const inviteBrandUserBody = new this.$BcmModel.InviteBrandUserBody(
          this.inviteEmailBrandUser
        );
        this.$apiInstance
          .inviteBrandUser(this.$route.params.brandUUID, inviteBrandUserBody)
          .then(
            (data) => {
              this.getBrandRoles();
              this.inviteEmailBrandUser = '';
              this.$notification.notify('SUCCESS',this.$t('HasBeenSuccessfullyAdded', { email: data.email }));
            },
            (error) => {
              /**
               * ERROR INVITE BRAND USER
               */
              this.inviteBrandUserErrorMessage = ApiErrorParser.parse(error);
            }
          );
      }
    },
    /**
     * CHANGE ROLES
     */
    roleChange() {
      this.roleModified = true;
    },
    /**
     * SUBMIT BRAND ROLES ACCESS / ADMIN
     */
    submitBrandAccessSecurity() {
      const rolesUserMapping = new this.$BcmModel.Roles();
      for (const role in rolesUserMapping) {
        rolesUserMapping[role] = [];
      }
      for (const userMapping of this.brandRoles) {
        if (userMapping.access) {
          rolesUserMapping.access.push(userMapping._id);
        }
        if (userMapping.admin) {
          rolesUserMapping.admin.push(userMapping._id);
        }
      }
      this.$apiInstance
        .modifyBrandRoles(this.$route.params.brandUUID, rolesUserMapping)
        .then(
          (data) => {
            this.brandRolesData = data;
            this.parseBrandRoles(this.brandRolesData);
            this.roleModified = false;
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$t('RoleSuccessfullyModified'));
          },
          (error) => {
            /**
             * ERROR SAVE BRAND ROLES
             */
            this.brandRolesErrorMessage = ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * RESET BRAND ROLES ACCESS / ADMIN
     */
    cancelBrandAccessSecurity() {
      this.parseBrandRoles(this.brandRolesData);
      this.roleModified = false;
    },
  },
};
</script>
