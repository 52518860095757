var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-3 mx-sm-5",
    attrs: {
      "flat": ""
    }
  }, [_vm.brandRolesErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.brandRolesErrorMessage) + " ")]) : _vm._e(), _vm.inviteBrandUserErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.inviteBrandUserErrorMessage) + " ")]) : _vm._e(), _vm.featuresErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.featuresErrorMessage) + " ")]) : _vm._e(), _c('v-card-title', [_vm._v(_vm._s(_vm.$t('ManageBrandUserSecurityAccess')))]), _c('v-card-text', [_vm.brand && _vm.brand.features.userQuota ? _c('v-text-field', {
    ref: "inviteEmailBrandUserForm",
    attrs: {
      "append-icon": "fa-plus-circle",
      "disabled": _vm.brandRoles.length === _vm.brand.features.userQuota,
      "label": "E-mail",
      "type": "email",
      "rules": [function (value) {
        return !!value.trim() || _vm.$t('nameRules');
      }, function (value) {
        return _vm.isEmail(value) || _vm.$t('PleaseEnterAValidEmail');
      }, function (value) {
        return !_vm.isBrandUserAlreadyExist(value) || _vm.$t('EmailDuplicatedInList');
      }],
      "outlined": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.inviteBrandUser.apply(null, arguments);
      },
      "click:append": _vm.inviteBrandUser
    },
    model: {
      value: _vm.inviteEmailBrandUser,
      callback: function ($$v) {
        _vm.inviteEmailBrandUser = $$v;
      },
      expression: "inviteEmailBrandUser"
    }
  }) : _vm._e(), _vm.brand && _vm.brandRoles.length === _vm.brand.features.userQuota ? _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "info",
      "text": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('userQuotaReached')) + " ")]) : _vm._e(), _c('v-divider')], 1), _c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchBrandRoles,
      callback: function ($$v) {
        _vm.searchBrandRoles = $$v;
      },
      expression: "searchBrandRoles"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersBrandRoles,
      "items": _vm.brandRoles,
      "search": _vm.searchBrandRoles,
      "loading": _vm.loadingTableRoles,
      "custom-filter": _vm.customFilter,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.user`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'User',
              params: {
                userUUID: `${item._id}`
              }
            }
          }
        }, [item.firstname !== ' ' || item.lastname !== ' ' ? _c('span', [_vm._v(" " + _vm._s(item.firstname) + " - "), _c('strong', [_vm._v(_vm._s(item.lastname))])]) : _c('span', [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("fas fa-pen")]), _vm._v(" " + _vm._s(_vm.$t('CompleteTheProfile')) + " ")], 1)]), _c('span', [_c('br'), _vm._v(_vm._s(item.email))])];
      }
    }, {
      key: `item.admin`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-switch', {
          attrs: {
            "color": "success",
            "inset": ""
          },
          on: {
            "change": _vm.roleChange
          },
          model: {
            value: item.admin,
            callback: function ($$v) {
              _vm.$set(item, "admin", $$v);
            },
            expression: "item.admin"
          }
        })];
      }
    }, {
      key: `item.access`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', {
          attrs: {
            "color": "success",
            "inset": ""
          },
          on: {
            "change": _vm.roleChange
          },
          model: {
            value: item.access,
            callback: function ($$v) {
              _vm.$set(item, "access", $$v);
            },
            expression: "item.access"
          }
        })];
      }
    }], null, true)
  })], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "disabled": !_vm.roleModified
    },
    on: {
      "click": _vm.cancelBrandAccessSecurity
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "disabled": !_vm.roleModified
    },
    on: {
      "click": _vm.submitBrandAccessSecurity
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }